<template>
  <div class="Security">
    <div
      class="SafetyAdvice"
      v-show="userinfo != undefined && userinfo.emailFlag == 0"
    >
      <div class="title regula30">{{ $t("lang.SText15") }}</div>
      <div class="content">
        <div class="content-left">
          <div class="line EMail">
            <div class="dian"></div>
            <div class="text">{{ $t("lang.SText16") }}</div>
            <div class="svg-icon">
              <svg-icon icon-class="right" class="svg-class"></svg-icon>
            </div>
          </div>
          <!-- <div class="line password">
            <div class="dian"></div>
            <div class="text">Update your password</div>
            <div class="svg-icon">
              <svg-icon icon-class="right" class="svg-class"></svg-icon>
            </div>
          </div>
          <div class="tag">Your password hasn’t been changed for 90 days.</div> -->
        </div>
        <div class="right-icon">
          <svg-icon icon-class="securityBack" class="svg-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="YourDevice">
        <div class="title regula30">{{ $t("lang.SText17") }}</div>
        <div
          class="content"
          v-if="devices.length > 0"
          v-loading="devicesLoading"
        >
          <div
            class="line"
            v-for="(item, index) in devices"
            :key="index"
            v-show="index < 3"
          >
            <div class="icon">
              <svg-icon
                v-if="item.deviceName.indexOf('windows') > 0"
                icon-class="phone"
                class="computer"
              ></svg-icon
              ><svg-icon
                v-else
                icon-class="computer"
                class="computer"
              ></svg-icon>
            </div>
            <div class="equipment">
              <div class="equipment-name">{{ item.deviceName }}</div>
              <div class="text" v-if="item.localMachine == 'true'">
                <div class="checkbox">
                  <svg-icon icon-class="yes"></svg-icon>
                </div>
                {{ $t("lang.SText18") }}
              </div>
              <div class="text" v-else>
                {{ item.remoteAddr }} {{ item.lastLoginTime }}
              </div>
            </div>
          </div>
          <div class="more" v-if="devices.length > 3">···</div>
        </div>
        <div class="manage" @click="Manage()" v-if="devices.length > 0">
          <div class="manage-left">{{ $t("lang.SText19") }}</div>
          <div class="button">
            <svg-icon icon-class="right" class="svg-icon"></svg-icon>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="line">
          <div class="icon">
            <svg-icon icon-class="security" class="svg-icon"></svg-icon>
          </div>
          <div class="title" @click="Changepassword()">
            {{ $t("lang.ChangePassword") }}
          </div>
          <svg-icon class="into-icon" icon-class="into"></svg-icon>
        </div>
        <div class="line">
          <div class="icon">
            <svg-icon class="svg-icon" icon-class="mine"></svg-icon>
          </div>
          <div class="title" @click="ChangeMemberid()">
            {{ $t("lang.SText20") }}
          </div>
          <svg-icon class="into-icon" icon-class="into"></svg-icon>
        </div>
        <div class="line" v-show="userinfo && userinfo.emailFlag == 0">
          <div class="icon">
            <svg-icon class="svg-icon" icon-class="eMail"></svg-icon>
          </div>
          <div class="title" @click="checkEmail()">
            {{ $t("lang.SText21") }}
          </div>
          <svg-icon class="into-icon" icon-class="into"></svg-icon>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="devicesListFlag" width="70%" @close="close" :destroy-on-close="true" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="computer"></svg-icon>
          {{ $t("lang.SText22") }}
        </div>
        <div class="close" @click="close()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="dialogMain">
        <div class="dialogMain-title">
          <div class="dialogMainLeft">{{ $t("lang.SText23") }}</div>
          <div class="dialogMainRight">{{ devices.length }} / 10</div>
        </div>
        <div class="dialogMain-main">
          <div class="deviceItem" v-for="(item, index) in devices" :key="index">
            <div class="icon">
              <svg-icon
                v-if="item.deviceName.indexOf('windows') > 0"
                icon-class="phone"
                class="computer"
              ></svg-icon
              ><svg-icon
                v-else
                icon-class="computer"
                class="computer"
              ></svg-icon>
            </div>
            <div class="equipment-type">
              {{ item.deviceName }}
            </div>
            <div class="text thisDevice" v-if="item.localMachine == 'true'">
              <div class="checkbox">
                <svg-icon icon-class="yes"></svg-icon>
              </div>
              {{ $t("lang.SText18") }}
            </div>
            <div class="text" v-else>
              <div>{{ item.remoteAddr }}</div>
              <div>{{ item.lastLoginTime }}</div>
            </div>
            <div
              class="signOut-icon"
              @mouseover="signOutHover(index)"
              @mouseleave="signOutHover(index, 1)"
            >
              ···
            </div>
            <div
              v-show="item.signOut"
              class="signOutHover"
              @mouseleave="signOutHover(index, 1)"
              @mouseover="signOutHover(index)"
            >
              <div class="signOut-icon">···</div>
              <div class="text" @click="clickSignOutAccount(item)">
                {{ $t("lang.SText24") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <dw-check-box
      :showCheckBox="showSignOut"
      @Cancel="Cancel"
      @Save="SignOutAccount()"
      :primaryButton="$t('lang.logout')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.SText25") }} “{{ this.signOutDevice.deviceName || "" }}
      {{ this.signOutDevice.remoteAddr || "" }}”
    </dw-check-box>
    <el-dialog
      :visible.sync="changePwdFlag"
      width="70%"
      @close="changePwdFlag = false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="security"></svg-icon>
          {{ $t("lang.ChangePassword") }}
        </div>
        <div class="close" @click="changePwdFlag = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="dialogMain yangshistyle">
        <el-row class="uploadPage">
          <el-form
            :inline="true"
            :model="changePwdForm"
            ref="changePwdForm"
            :rules="rules"
            class="search-form-inline changePwdForm"
            v-if="isshowForm"
          >
            <el-form-item :label="$t('lang.text1')" prop="oldPassword">
              <el-input
                v-model="changePwdForm.oldPassword"
                placeholder=""
                :type="oldpassw"
                autocomplete="off"
              >
                <svg-icon
                  :icon-class="elIcon"
                  @click="flag = !flag"
                  slot="suffix"
                ></svg-icon>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('lang.text2new')" prop="password">
              <el-input
                v-model="changePwdForm.password"
                placeholder=""
                :type="passw"
                autocomplete="off"
              >
                <svg-icon
                  :icon-class="elIcon1"
                  @click="flag1 = !flag1"
                  slot="suffix"
                ></svg-icon>
              </el-input>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('lang.text4')"
                placement="bottom"
                v-if="ruleFormpassword"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item :label="$t('lang.text3')" prop="rePassword">
              <el-input
                v-model="changePwdForm.rePassword"
                placeholder=""
                :type="newpassw"
                autocomplete="off"
              >
                <svg-icon
                  :icon-class="elIcon2"
                  @click="flag2 = !flag2"
                  slot="suffix"
                ></svg-icon>
              </el-input>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('lang.text4')"
                placement="bottom"
                v-if="ruleFormrePassword"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </el-form-item>
          </el-form>
          <p class="xiugaicheng" v-if="isshowText">
            {{ $t("lang.SText26") }}<br />{{ $t("lang.SText27") }}
          </p>
          <span class="daojishi" v-if="isshowText"
            >{{ $t("lang.SText28") }} {{ count2 }}{{ $t("lang.SText29") }}</span
          >
          <el-button class="signinPS" @click="tiSignin" v-if="isshowText">{{
            $t("lang.SText30")
          }}</el-button>
          <div class="bottom-button" style="margin-top: 30px" v-if="isshowForm">
            <el-button
              :loading="SavepwdLoading"
              type="primary"
              @click="Savepwd('changePwdForm')"
              >{{ $t("lang.SText13") }}
            </el-button>
            <el-button
              type="primary"
              style="background: transparent; color: #132569"
              @click="changePwdFlag = false"
              >{{ $t("lang.QText79") }}
            </el-button>
          </div>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="changeMemberFlag"
      width="70%"
      @close="changeMemberFlag = false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="security"></svg-icon>
          {{ $t("lang.ChangeMemberID") }}
        </div>
        <div class="close" @click="changeMemberFlag = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="processReset">
        <span :class="{ sss: typeOne == true }"></span>
        <ul>
          <li class="color132569" :class="{ color299BE4: typeOne == true }">
            <h6>{{ $t("lang.SText31") }}</h6>
            <div>1</div>
          </li>
          <li
            :class="{
              color132569: typeOne == true,
              color299BE4: typeTwo == true,
            }"
          >
            <h6>{{ $t("lang.SText32") }}</h6>
            <div>2</div>
          </li>
        </ul>
      </div>

      <span class="solidLine"></span>
      <div class="dialogMain">
        <el-row class="uploadPage">
          <el-form
            :inline="true"
            :model="changeMemberForm"
            ref="changeMemberForm"
            :rules="ruleschangeMember"
            class="search-form-inline changePwdForm"
          >
            <el-form-item
              :label="$t('lang.text10')"
              prop="loginCode"
              v-if="isShow == true"
            >
              <el-input
                v-model="changeMemberForm.loginCode"
                placeholder=""
                type="email"
                autocomplete="off"
                readonly=""
              />
            </el-form-item>
            <el-form-item
              :label="$t('lang.text12')"
              prop="code"
              v-if="isShow == true"
              class="unspin"
            >
              <el-input
                v-model="changeMemberForm.code"
                placeholder=""
                type="number"
                @keydown.native="limite"
                autocomplete="off"
                style="width: 50%"
              />
              <a @click="sendEmail" v-if="isShowSendTrue">{{
                $t("lang.text15")
              }}</a>
              <a v-if="isShowSendFalse">{{ $t("lang.text18") }}</a>
              <a
                style="text-decoration: none; color: #cccccc"
                v-if="isShowSendTimer"
                >{{ $t("lang.SText33") }} {{ count }}{{ $t("lang.SText29") }}
                {{ $t("lang.SText34") }}
              </a>
            </el-form-item>
            <el-form-item
              :label="$t('lang.text11')"
              prop="newLoginCode"
              v-if="isShowNext == true"
            >
              <el-input
                v-model="changeMemberForm.newLoginCode"
                placeholder=""
                type="email"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item
              :label="$t('lang.text12')"
              prop="code"
              v-if="isShowNext == true"
              class="unspin"
            >
              <el-input
                v-model="changeMemberForm.codeNext"
                placeholder=""
                type="number"
                @keydown.native="limite"
                autocomplete="off"
                style="width: 50%"
              />
              <a @click="sendEmailNext" v-if="isShowSendTrue1">{{
                $t("lang.text15")
              }}</a>
              <a v-if="isShowSendFalse1">{{ $t("lang.text18") }}</a>
              <a
                style="text-decoration: none; color: #cccccc"
                v-if="isShowSendTimer1"
                >{{ $t("lang.SText33") }} {{ count1 }}{{ $t("lang.SText29") }}
                {{ $t("lang.SText34") }}</a
              >
            </el-form-item>
          </el-form>
          <p class="xiugaicheng" v-if="isshowTextid&&$i18n.locale == 'zh_cn'">
            {{ $t("lang.SText35") }}<br />请用
            <span>{{ changeMemberForm.newLoginCode }}</span>登录
          </p>
          <p class="xiugaicheng" v-else-if="isshowTextid&&$i18n.locale == 'en_us'">
            {{ $t("lang.SText35") }}<br />Please sign in with
            <span>{{ changeMemberForm.newLoginCode }}</span>
          </p>
          <span class="daojishi" v-if="isshowTextid&&$i18n.locale == 'zh_cn'"
            >在 {{ count3 }}s 后自动进入登录界面</span
          >
          <span class="daojishi" v-else-if="isshowTextid&&$i18n.locale == 'en_us'"
            >Automatically enter the sign in page after {{ count3 }}s</span
          >
          <el-button class="signinPS" @click="tiSignin" v-if="isshowTextid">{{
            $t("lang.SText30")
          }}</el-button>
          <div class="bottom-button" style="margin-top: 30px">
            <el-button
              type="primary"
              @click="Next('changeMemberForm')"
              v-if="isShow == true"
              >{{ $t("lang.text13") }}</el-button
            >
            <el-button
              type="primary"
              @click="Submit('changeMemberForm')"
              v-if="isShowNext == true"
              :loading="loadingBtn"
              >{{ $t("lang.text14") }}</el-button
            >
          </div>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import utils from "../../../utils/util";
import global from "../../../utils/global";
export default {
  components: {
    dwCheckBox,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("lang.text8")));
      } else {
        if (this.changePwdForm.rePassword !== "") {
          this.$refs.changePwdForm.validateField("rePassword");
        }
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        this.ruleFormpassword = true;
        callback(new Error(this.$t("lang.text8")));
      } else if (
        value.indexOf(this.changePwdForm.loginCode.split("@")[0]) != -1
      ) {
        this.ruleFormpassword = true;
        callback(new Error(this.$t("lang.text4")));
      } else {
        if (this.changePwdForm.rePassword !== "") {
          this.$refs.changePwdForm.validateField("rePassword");
        }
        this.ruleFormpassword = false;
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        this.ruleFormrePassword = true;
        callback(new Error(this.$t("lang.text8")));
      } else if (value !== this.changePwdForm.password) {
        this.ruleFormrePassword = true;
        callback(new Error(this.$t("lang.text7")));
      } else if (
        value.indexOf(this.changePwdForm.loginCode.split("@")[0]) != -1
      ) {
        this.ruleFormrePassword = true;
        callback(new Error(this.$t("lang.text4")));
      } else {
        this.ruleFormrePassword = false;
        callback();
      }
    };
    var validatePass4 = (rule, value, callback) => {
      console.log(isEmail(value));
      if (value === "") {
        callback(new Error(this.$t("lang.text8")));
      } else if (isEmail(value) == false) {
        callback(new Error(this.$t("lang.text17")));
      } else if (value.indexOf("@de-well.com") != -1) {
        callback(new Error(this.$t("lang.text16")));
      } else {
        callback();
      }
    };
    function isEmail(str) {
      // var myReg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      var myReg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
      if (myReg.test(str)) {
        return true;
      } else {
        return false;
      }
    }
    return {
      loadingBtn: false,
      typeOne: false,
      typeTwo: false,
      isshowTextid: false,
      isShowMid: true,
      isshowText: false,
      isshowForm: true,
      isShowSendTimer: false,
      isShowSendTrue: true,
      isShowSendFalse: false,
      isShowSendTimer1: false,
      isShowSendTrue1: true,
      isShowSendFalse1: false,
      devices: [],
      msg: "",
      flag: true,
      flag1: true,
      flag2: true,
      ruleFormpassword: false,
      ruleFormrePassword: false,
      changeMemberFlag: false,
      devicesListFlag: false,
      changePwdFlag: false,
      showSignOut: false,
      isShow: true,
      isShowNext: false,
      devicesLoading: true, //Your devices loading
      signOutDevice: {}, //点击登出的设备
      changePwdForm: {
        oldPassword: "",
        password: "",
        rePassword: "",
        loginCode: "",
      },
      changeMemberForm: {
        loginCode: "",
        code: "",
        newLoginCode: "",
        codeNext: "",
      },
      ruleschangeMember: {
        newLoginCode: [{ validator: validatePass4, trigger: "blur" }],
      },
      userinfo: {},
      token: "",
      rules: {
        oldPassword: [
          { validator: validatePass, trigger: "blur" },
          { min: 8, max: 18, message: this.$t("lang.text4"), trigger: "blur" },
        ],
        password: [
          { validator: validatePass3, trigger: "blur" },
          { min: 8, max: 18, message: this.$t("lang.text4"), trigger: "blur" },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              // var passwordreg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/;
              var passwordreg =
                /^(?=.*[a-z]+)(?=.*?[A-Z])(?=.*[0-9]+)[a-zA-Z0-9]+$/;
              console.log(passwordreg.test(value));
              if (!passwordreg.test(value)) {
                this.ruleFormpassword = true;
                callback(new Error(this.$t("lang.text4")));
              } else {
                this.ruleFormpassword = false;
                callback();
              }
            },
          },
        ],
        rePassword: [
          { validator: validatePass2, trigger: "blur" },
          { min: 8, max: 18, message: this.$t("lang.text4"), trigger: "blur" },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              // var passwordreg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/;
              var passwordreg =
                /^(?=.*[a-z]+)(?=.*?[A-Z])(?=.*[0-9]+)[a-zA-Z0-9]+$/;
              console.log(passwordreg.test(value));
              if (!passwordreg.test(value)) {
                this.ruleFormrePassword = true;
                callback(new Error(this.$t("lang.text4")));
              } else {
                this.ruleFormrePassword = false;
                callback();
              }
            },
          },
        ],
      },
      count: "",
      timer: null,
      count1: "",
      timer1: null,
      count2: "",
      timer2: null,
      count3: "",
      timer3: null,
      SavepwdLoading: false,
    };
  },
  mounted: function () {
    this.initData();
  },
  created() {
    this.getList();
    if (this.$route.params.changePassword) {
      this.changePwdFlag = true;
    }
    if (this.$route.params.changeMemberFlag) {
      this.changeMemberFlag = true;
    }
  },
  computed: {
    oldpassw() {
      return this.flag ? "password" : "text";
    },
    elIcon() {
      return this.flag ? "invisible" : "visible";
    },
    passw() {
      return this.flag1 ? "password" : "text";
    },
    elIcon1() {
      return this.flag1 ? "invisible" : "visible";
    },
    newpassw() {
      return this.flag2 ? "password" : "text";
    },
    elIcon2() {
      return this.flag2 ? "invisible" : "visible";
    },
  },
  methods: {
    limite(e){
      let key=e.key;
      if(key === 'e'||key === '.'){
        e.returnValue = false;
        return false;
      }
      return true;
    },
    getPhoneCode() {
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            if (this.count == 0) {
              this.isShowSendTrue = true;
              this.isShowSendFalse = false;
              this.isShowSendTimer = false;
            }
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    getPhoneCode1() {
      let TIME_COUNT = 60;
      if (!this.timer1) {
        this.count1 = TIME_COUNT;
        this.show1 = false;
        this.timer1 = setInterval(() => {
          if (this.count1 > 0 && this.count1 <= TIME_COUNT) {
            this.count1--;
            if (this.count1 == 0) {
              this.isShowSendTrue1 = true;
              this.isShowSendFalse1 = false;
              this.isShowSendTimer1 = false;
            }
          } else {
            this.show1 = true;
            clearInterval(this.timer1);
            this.timer1 = null;
          }
        }, 1000);
      }
    },
    getPhoneCode2() {
      let TIME_COUNT = 5;
      if (!this.timer2) {
        this.count2 = TIME_COUNT;
        this.show2 = false;
        this.timer2 = setInterval(() => {
          if (this.count2 > 0 && this.count2 <= TIME_COUNT) {
            this.count2--;
            if (this.count2 == 0) {
              this.$router.replace('/login')
              clearInterval(this.timer2);
            }
          } else {
            this.show2 = true;
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000);
      }
    },
    getPhoneCode3() {
      let TIME_COUNT = 5;
      if (!this.timer3) {
        this.count3 = TIME_COUNT;
        this.show3 = false;
        this.timer3 = setInterval(() => {
          if (this.count3 > 0 && this.count3 <= TIME_COUNT) {
            this.count3--;
            if (this.count3 == 0) {
              this.$router.replace('/login')
              clearInterval(this.timer3);
            }
          } else {
            this.show3 = true;
            clearInterval(this.timer3);
            this.timer3 = null;
          }
        }, 1000);
      }
    },
    tiSignin() {
      this.$router.replace('/login')
    },
    initData: function () {
      this.$session("userInfo").get((value) => {
        this.userinfo = value;
      });
      this.$session("token").get((value) => {
        this.token = value;
      });
      var that = this;
      that.changePwdForm.loginCode = that.userinfo.loginCode;
      that.changeMemberForm.loginCode = that.userinfo.loginCode;
    },
    checkEmail() {
      var user = this.userinfo;
      let data = {
        loginCode: user.loginCode,
      };
      this.$ajax
        .cPost(this.source.account_api.checkMembereEmail, data)
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
        });
    },
    isEmail(str) {
      console.log(str);
      // var myReg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      var myReg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
      if (myReg.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    sendEmail() {
      this.isShowSendTrue = false;
      this.isShowSendFalse = true;
      this.isShowSendTimer = false;
      let data = {
        loginCode: this.changeMemberForm.loginCode,
        __sid: this.token,
      };
      this.$ajax
        .cPost(this.source.account_api.changeIDCode, data)
        .then((res) => {
          if (res.data.result == "true") {
            this.isShowSendFalse = false;
            this.isShowSendTrue = false;
            this.isShowSendTimer = true;
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
            this.changeMemberFlag = true;
            this.getPhoneCode();
          } else {
            this.isShowSendFalse = false;
            this.isShowSendTrue = true;
            this.isShowSendTimer = false;
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
            this.changeMemberFlag = true;
          }
        });
    },
    Next: function (formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.checkEmailCode();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkEmailCode() {
      let data = {
        code: this.changeMemberForm.code,
        __sid: this.token,
        // __sid:"e059ea5151f34a5580eb9c771e7ba842",
        loginCode: this.changeMemberForm.loginCode,
      };
      this.$ajax
        .cPost(this.source.account_api.checkIDCode, data)
        .then((res) => {
          if (res.data.result == "true") {
            this.typeOne = true;
            this.isShowSendFalse = false;
            this.isShowSendTrue = false;
            this.isShowSendTimer = false;
            this.isShowSendFalse1 = false;
            this.isShowSendTrue1 = true;
            this.isShowSendTimer1 = false;
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
            this.isShow = false;
            this.isShowNext = true;
            this.changeMemberFlag = true;
          } else {
            this.isShowSendFalse = false;
            this.isShowSendTrue = true;
            this.isShowSendTimer = false;
            this.typeOne = false;
            this.isShowSendFalse1 = false;
            this.isShowSendTrue1 = false;
            this.isShowSendTimer1 = false;
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
            this.isShow = true;
            this.isShowNext = false;
            this.changeMemberFlag = true;
          }
        });
    },
    sendEmailNext() {
      if (this.changeMemberForm.newLoginCode == "") {
        this.$notify({
          title: this.$t("lang.text8"),
          type: "error",
          offset: 100,
        });
      } else if (this.isEmail(this.changeMemberForm.newLoginCode) == false) {
        this.$notify({
          title: this.$t("lang.text17"),
          type: "error",
          offset: 100,
        });
      } else if (
        this.changeMemberForm.newLoginCode.indexOf("@de-well.com") != -1
      ) {
        this.$notify({
          title: this.$t("lang.text16"),
          type: "error",
          offset: 100,
        });
      } else {
        this.isShowSendTrue1 = false;
        this.isShowSendFalse1 = true;
        this.isShowSendTimer1 = false;
        let data = {
          newLoginCode: this.changeMemberForm.newLoginCode,
          __sid: this.token,
          // __sid:"e059ea5151f34a5580eb9c771e7ba842",
        };
        this.$ajax
          .cPost(this.source.account_api.changeIDCodeNext, data)
          .then((res) => {
            if (res.data.result == "true") {
              this.getPhoneCode1();
              this.isShowSendFalse1 = false;
              this.isShowSendTrue1 = false;
              this.isShowSendTimer1 = true;
              this.$notify({
                title: res.data.message,
                type: "success",
                offset: 100,
              });
              this.changeMemberFlag = true;
            } else {
              this.isShowSendFalse1 = false;
              this.isShowSendTrue1 = true;
              this.isShowSendTimer1 = false;
              this.$notify({
                title: res.data.message,
                type: "error",
                offset: 100,
              });
              this.changeMemberFlag = true;
            }
          });
      }
    },
    Submit: function (formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.checkEmailCodeNext();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkEmailCodeNext() {
      this.loadingBtn = true;
      let data = {
        code: this.changeMemberForm.codeNext,
        __sid: this.token,
        // __sid:"e059ea5151f34a5580eb9c771e7ba842",
        loginCode: this.changeMemberForm.loginCode,
        newLoginCode: this.changeMemberForm.newLoginCode,
      };
      this.$ajax
        .cPost(this.source.account_api.checkIDCodeNext, data)
        .then((res) => {
          if (res.data.result == "true") {
            this.loadingBtn = false;
            this.isShowSendFalse1 = false;
            this.isShowSendTrue1 = false;
            this.isShowSendTimer1 = false;
            this.isShow = false;
            this.isShowNext = false;
            this.isshowTextid = true;
            this.typeTwo = true;
            this.getPhoneCode3();
            sessionStorage.removeItem("@userInfo");
            sessionStorage.removeItem("token");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("loginInfo");
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
            this.changeMemberFlag = true;
          } else {
            this.loadingBtn = false;
            this.isShowSendFalse1 = false;
            this.isShowSendTrue1 = true;
            this.isShowSendTimer1 = false;
            this.typeTwo = false;
            this.isShow = false;
            this.isShowNext = true;
            this.isshowTextid = false;
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
            this.changeMemberFlag = true;
          }
        });
    },
    Savepwd: function (formName) {
      console.log(formName);
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.signUppwd();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // __sid:"a512dd21be84434bb091d8158eef119e",
    // loginCode:'liqianwen@de-well.com'
    signUppwd() {
      let data = {
        password: this.changePwdForm.password,
        oldPassword: this.changePwdForm.oldPassword,
        __sid: this.token,
        // __sid:"e059ea5151f34a5580eb9c771e7ba842",
        loginCode: this.changePwdForm.loginCode,
      };
      this.SavepwdLoading = true;
      this.$ajax.cPost(this.source.account_api.changepwd, data).then((res) => {
        if (res.data.result == "true") {
          // sessionStorage.setItem('user', '');
          this.isshowForm = false;
          this.isshowText = true;
          this.getPhoneCode2();
          this.changePwdFlag = true;
          this.$notify({
            title: res.data.message,
            type: "success",
            offset: 100,
          });
          sessionStorage.removeItem("@userInfo");
          sessionStorage.removeItem("token");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("loginInfo");
        } else {
          this.isshowForm = true;
          this.isshowText = false;
          // this.getPhoneCode2()
          // this.isshowForm=false
          // this.isshowText=true
          // this.sendFun({ m: res.data.message, t: "Auto hide" });
          this.$notify({
            title: res.data.message,
            type: "error",
            offset: 100,
          });
          this.changePwdFlag = true;
        }
        this.SavepwdLoading = false;
      });
    },
    sendFun: function (msg, fun) {
      this.$notify({
        title: msg,
        type: "success",
        offset: 100,
      });
      setTimeout(function () {
        if (typeof fun == "function") {
          fun();
        }
      }, 3000);
    },
    getList() {
      const visitorId = utils.cans();
      this.$session("userInfo").get((userInfo) => {
        let data = {
          userId: userInfo.id,
          pageNo: "1",
          pageSize: "10",
          deviceId: userInfo.id,
        };
        this.$ajax.cPost(this.source.device_api.listData, data).then((res) => {
          if (res.data.result == "true") {
            this.devices = res.data.list;
          }
          this.devicesLoading = false;
        });
      });
    },
    Changepassword() {
      this.changePwdFlag = true;
      this.changePwdForm.oldPassword = "";
      this.changePwdForm.password = "";
      this.changePwdForm.rePassword = "";
    },
    ChangeMemberid() {
      this.changeMemberFlag = true;
      this.isShow = true;
      this.isShowNext = false;
      this.changeMemberForm.code = "";
      this.changeMemberForm.newLoginCode = "";
      this.changeMemberForm.codeNext = "";
    },
    Manage() {
      this.devicesListFlag = true;
    },
    close() {
      this.devicesListFlag = false;
    },
    signOutHover(index, type) {
      if (type) {
        this.$set(this.devices[index], "signOut", false);
        return;
      }
      this.$set(this.devices[index], "signOut", true);
    },
    Cancel() {
      this.showSignOut = false;
    },
    clickSignOutAccount(device) {
      this.showSignOut = true;
      this.signOutDevice = device;
    },
    SignOutAccount() {
      this.showSignOut = false;
      let data = {
        id: this.signOutDevice.id,
      };
      this.$ajax
        .cPost(this.source.device_api.delAutomaticLogon, data)
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify({
              title: this.$t("lang.SText145"),
              type: "success",
              offset: 100,
            });
            this.getList();
          }
          this.devicesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.Security {
  width: 900px;
  margin-left: 150px;
  .SafetyAdvice,
  .YourDevice {
    border: 1px solid #e6e6e6;
    .title {
      height: 79px;
      line-height: 79px;
      color: $mainColor;
      background-color: $background1;
      padding-left: 20px;
      border-bottom: 1px solid #e6e6e6;
      text-align: left;
    }
  }

  .SafetyAdvice {
    margin-bottom: 30px;

    .content {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      .content-left {
        .line {
          display: flex;
          height: 40px;
          .dian {
            background-color: $mainColor;
            height: 8px;
            width: 8px;
            margin: 16px 20px 0 0;
          }
          .text {
            font-size: 20px;
            color: $mainColor;
            line-height: 40px;
          }
          .svg-icon {
            height: 16px;
            width: 16px;
            margin-left: 50px;
            margin-top: 5px;
            fill: $mainColor;
            stroke: $mainColor;
            .svg-class {
              height: 16px;
              width: 16px;
              color: $mainColor;
            }
          }
        }
        .tag {
          font-size: 14px;
          color: #b3b3b3;
          text-align: left;
          margin-left: 28px;
          margin-top: 20px;
        }
      }
      .right-icon {
        margin-right: 30px;
        .svg-icon {
          height: 120px;
          width: 167px;
        }
      }
    }
  }

  .middle {
    display: flex;
    justify-content: space-between;
  }
  .changePwdForm {
    .el-form-item {
      width: 60%;
      label {
        width: 80%;
        font-family: "montserratregular";
        color: #132569;
        font-size: 27px;
        padding: 0;
      }
      .el-form-item__content {
        width: 80%;
        text-align: left;
        .el-input {
          width: 90%;
        }
        a {
          text-decoration: underline;
          color: #299be4;
          margin-top: 10px;
          line-height: 60px;
          margin-left: 3%;
          text-decoration: underline;
          font-size: 20px;
          cursor: pointer;
          font-family: "Montserrat";
        }
      }
    }
  }
  .processReset {
    height: 22vh;
    width: 100%;
    background-color: #f2f2f2;
    padding: 0 20%;
    box-sizing: border-box;
    position: relative;
  }
  .processReset span {
    position: absolute;
    left: 30%;
    bottom: 9vh;
    width: calc(100% - 60%);
    z-index: 1;
    border-bottom: 2px dashed #b3b3b3;
  }
  .processReset span.sss {
    border-bottom: 2px solid #132569;
  }
  .processReset span.solidLine {
    z-index: 2;
    border-bottom: 2px solid #299be4;
    display: none;
  }
  .processReset ul {
    position: absolute;
    left: 30%;
    width: 40%;
    height: 100px;
    bottom: 85px;
    z-index: 3;
    padding: 0;
  }
  .processReset span.solidLine {
    width: 50%;
  }
  .processReset ul li:nth-child(1) {
    list-style: none;
    position: absolute;
    left: -52px;
    width: 50%;
    text-align: left;
  }
  .processReset ul li:nth-child(2) {
    list-style: none;
    position: absolute;
    left: 100%;
    width: 50%;
    text-align: left;
  }
  .processReset ul li h6 {
    line-height: 25px;
    margin-bottom: 15px;
    font-size: 20px;
    color: #999999;
    font-family: "Montserrat";
  }
  .processReset ul li div {
    width: 50px;
    height: 50px;
    background-color: #b3b3b3;
    line-height: 50px;
    text-align: center;
    font-size: 1vw;
    color: #fff;
    font-family: "Montserrat";
  }
  .processReset ul li.color132569 h6 {
    color: #299be4;
  }
  .processReset ul li.color132569 div {
    background-color: #299be4;
  }
  .processReset ul li.color299BE4 h6 {
    color: #132569;
  }
  .processReset ul li.color299BE4 div {
    background-color: transparent;
    color: #132569;
    border: 2px solid #132569;
  }
  .xiugaicheng {
    font-family: "montserratregular";
    color: #132569;
    font-size: 40px;
    line-height: 75px;
    text-align: left;
    width: 70%;
    margin: 100px auto 0;
    span {
      color: #299be4;
    }
  }
  .daojishi {
    font-family: "montserratregular";
    color: #b3b3b3;
    font-size: 14px;
    text-align: left;
    width: 70%;
    float: left;
    margin-left: 15%;
    margin-top: 20px;
  }
  .signinPS {
    width: 220px;
    float: left;
    margin-left: 15%;
    margin-top: 40px;
    background: #eb4b4b;
    line-height: 32px;
    color: #fff;
    font-size: 20px;
  }
  .YourDevice {
    width: 415px;
    .content {
      padding: 10px 20px;
      min-height: 80px;
      .line {
        display: flex;
        text-align: left;
        margin-bottom: 10px;

        .icon {
          margin: 10px 0;
          .svg-icon {
            width: 32px;
            height: 30px;
            fill: #b3b3b3;
            stroke: #b3b3b3;
          }
        }
        .equipment {
          margin: 0 20px;
          .equipment-name {
            font-size: 20px;
            color: $mainColor;
            line-height: 35px;
            font-family: "montserratLight";
          }
          .text {
            color: $fontColor1;
            font-size: 14px;
            display: flex;
            font-family: "montserratLight";
            .checkbox {
              margin: 0 5px;
              display: flex;
              align-items: center;
              .svg-icon {
                margin: auto;
              }
            }
          }
        }
      }
      .more {
        font-size: 36px;
        color: #b3b3b3;
        line-height: 30px;
      }
    }

    .manage {
      display: flex;
      color: $background2;
      font-size: 20px;
      height: 60px;
      justify-content: space-between;
      padding: 0 20px;
      border-top: 1px solid #e6e6e6;
      cursor: pointer;

      .manage-left {
        line-height: 60px;
      }
      .button {
        .svg-icon {
          margin: 20px 0;
          height: 20px;
          width: 20px;
          fill: #b3b3b3;
          stroke: #b3b3b3;
        }
      }
    }
  }

  .right {
    width: 415px;
    font-size: 20px;
    color: $mainColor;
    border-top: 1px solid #e6e6e6;

    .line {
      display: flex;
      height: 79px;
      line-height: 79px;
      border-left: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      cursor: pointer;
      .icon {
        height: 79px;
        width: 79px;
        display: flex;
        align-items: center;
        .svg-icon {
          margin: auto;
          height: 20px;
          width: 20px;
          fill: $mainColor;
          stroke: $mainColor;
        }
      }
      .title {
        width: 280px;
        text-align: left;
        letter-spacing: 0;
      }

      .into-icon {
        height: 20px;
        width: 20px;
        color: $mainColor;
        margin: 30px 10px;
        line-height: 79px;
      }
    }
  }

  .el-dialog {
    margin-top: 0 !important;
    min-height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      min-height: 100%;
    }
  }
  .yangshistyle {
    .el-input__suffix {
      line-height: 60px;
      font-size: 20px;
      right: 15px;
    }
  }
  .dialogMain {
    padding: 40px 50px;
    .dialogMain-title {
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      color: $mainColor;
    }
    .dialogMain-main {
      display: flex;
      flex-wrap: wrap;

      // justify-content: space-between;
      padding: 40px 20px;
      .deviceItem {
        height: 130px;
        width: 190px;
        border: 1px solid $borderColor1;
        text-align: left;
        padding: 20px 30px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        .computer {
          height: 50px;
          width: 50px;
          fill: #b3b3b3;
          stroke: #b3b3b3;
        }
        .equipment-type {
          font-size: 20px;
          font-weight: 100;
          font-family: "montserratLight";
          color: $mainColor;
          margin-top: 20px;
        }
        .text {
          color: $fontColor1;
          font-size: 14px;
          line-height: 20px;
          margin-top: 5px;
        }
        .thisDevice {
          display: flex;
        }
        .signOut-icon {
          position: absolute;
          right: 20px;
          top: 0;
          color: $fontColor2;
          font-size: 50px;
          cursor: pointer;
        }
        .signOutHover {
          position: absolute;
          right: 10px;
          top: 10px;
          height: 100px;
          width: 220px;
          .signOut-icon {
            position: absolute;
            right: 0;
            top: 0;
            color: $mainColor;
            font-size: 50px;
            width: 50px;
            height: 30px;
            background-color: $background1;
            line-height: 30px;
            text-align: center;
            border-top: 1px solid $borderColor1;
            border-left: 1px solid $borderColor1;
            border-right: 1px solid $borderColor1;
            z-index: 99;
          }
          .text {
            position: absolute;
            right: 0;
            top: 24px;
            width: 220px;
            height: 70px;
            background-color: $background1;
            font-size: 20px;
            text-align: center;
            border: 1px solid $borderColor1;
            line-height: 70px;
            text-decoration: underline;
            color: $mainColor;
            cursor: pointer;
          }
        }
      }
    }
  }
  .checkbox {
    color: #fff;
    background-color: $background2;
    height: 14px;
    width: 14px;
    line-height: 6px;
    text-align: center;
    margin-right: 5px;
    margin-top: 3px;
    display: flex;
    align-items: center;
    .svg-icon {
      width: 10px;
      height: 6px;
      margin: auto;
      fill: #fff;
      stroke: #fff;
    }
  }
}
</style>
